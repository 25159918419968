function lightboxes() {

    // Change defaults
    Fancybox.defaults.infinite = 0;
    Fancybox.defaults.animationEffect = "fade";
    Fancybox.defaults.transitionEffect = "fade";
    Fancybox.defaults.transitionDuration = "400";

    // Start Fancybox with custom options when user clicks on the matching element
    if ( !! $j('.qmi-gallery').length ) {
	    var qmiclasses = '';
	    var qmis = [];
        var qmis = [];
        function uniqueArray0(array) {
            var result = Array.from(new Set(array));
            return result
        }
        jQuery('.qmi-gallery').each(function(index){
            var el = $j(this);
            qmis.push(el.data('fancybox'));
        });
        qmis = uniqueArray0(qmis);
        $j.each( qmis, function( key, value ) {
            qmiclasses += ', [data-fancybox="'+value+'"]';
        });

        $j('.start-gal').click(function(){
            var el = $j(this);
            var gal = el.data('gal');
            Fancybox.bind('[data-fancybox="'+gal+'"]:first').click();
        });
    }

    $j('a[data-event]').click(function(){
        $j('input[name="event_name"]').val($j(this).data('event'));
        $j('.rsvp-subtitle').text($j(this).data('event')+' on '+$j(this).data('date'));
    });


    Fancybox.bind('[data-fancybox]', {
        buttons: ["zoom", "slideShow", "download", "thumbs", "close"],
    });

    Fancybox.bind('[data-fancybox="event"]', {
        //toolbar  : false,
        //smallBtn : true,
        hash : false,
        iframe : {
            preload : false
        },
        mobile: {
            clickOutside : 'close',
            clickSlide: "close",
            preventCaptionOverlap: false,
            idleTime: false,
            clickContent: function(current, event) {
                return current.type === "image" ? "zoom" : false;
            },
            clickSlide: function(current, event) {
                return current.type === "image" ? "close" : "close";
            },
            dblclickContent: function(current, event) {
                return current.type === "image" ? "zoom" : false;
            },
            dblclickSlide: function(current, event) {
                return current.type === "image" ? "zoom" : false;
            }
        },
        afterClose: function( instance, current ) {
            var cookie = $j('.ppauto').data('cookie');
            var expires = $j('.ppauto').data('expires');
            createCookie(cookie, 10, expires);
        }
    });

    Fancybox.bind('[data-type="iframe"]', {
        clickOutside : 'close',
        clickSlide: "close",
        media : {
            youtube : {
                params : {
                    autoplay : 1,
                    fs : 1,
                    iv_load_policy : 3,
                    rel : 0,
                    showinfo : 0,
                    showsearch : 0,
                    modestbranding : 1,
                    color : 'white'
                }
            }
        }
    });

    Fancybox.bind('[data-fancybox][data-type="image"]', {
        buttons: ["zoom", "slideShow", "download", "thumbs", "close"],
    });

    Fancybox.bind('[data-fancybox="gallery"]', {
        animationEffect: "zoom",
        thumbs: {
            autoStart : false
        },
        buttons: ["zoom", "slideShow", "download", "thumbs", "close"],
        autoplay: false,
        infobar: true,
        slideShow: {
            autoStart: false,
            speed: 3600
        }
    });

    Fancybox.bind('[data-fancybox="image"], [data-fancybox="community-gallery"] '+qmiclasses, {
        animationEffect: "fade",
        thumbs: {
            autoStart : true
        },
        buttons: ["zoom", "slideShow", "fullScreen", "download", "thumbs", "close"],
        autoplay: true,
        infobar: true,
        slideShow: {
            autoStart: true,
            speed: 10000
        }
    });

    $j(document).on('.fancybox-stage .btn-schedule, .close-fancy', 'click', function(e){
        e.preventDefault();
        Fancybox.close(true);
        log('ran');
    });
    Fancybox.bind('[data-fancybox="floorplan-zoom"]', {
        buttons: ["zoom", "fullScreen", "close"],
        afterShow :function() {
            //$j('.fancybox-button--zoom').click();
        }
    });

    $j(document).bind("keyup", function (event) {
        if ( event.keyCode == 27 ) {
            $j('.btn.subscribe-close').click();
            Fancybox.close(true);
        }
    });

	$j('.btn-gallery-more').click(function(e) {
		var el = $j(this);
		var target = el.data('target');
		$j('div.hide.'+target+':first').removeClass('hide').next().removeClass('hide').next().removeClass('hide');
		var rows = $j('div.hide.'+target).length;
		if ( ! rows ) {
			el.remove();
		}
		bLazy.revalidate();
		setTimeout(function() {
			bLazy.revalidate();
		}, 1000);
	});

}
