function scrollProgress() {
    if ( ! $j('div.scroll-progress').length ) {
        return;
    }
    //GET VARIABLES
    $j(window).on('ready load resizeEnd', function() {
        var $articleContent = $j('div.scroll-progress'),
                panelHeight = 90,
                articleOffset = $articleContent.offset();

        window.headerHeight = $j('#header').height();
        window.articleHeight = $articleContent.height();
        window.windowHeight = $j(window).height();
        window.articleOffsetTop = articleOffset.top - panelHeight;
    });
    //ON SCROLL
    $j(window).on('scroll ready load resize resizeEnd', function(event) {
        var scrollTop = $j(window).scrollTop();
        var scrollProgress = ((scrollTop - window.articleOffsetTop) / (window.articleHeight - window.windowHeight / 6));
        //log('scrolled', scrollTop, scrollProgress);
        // HEADER ANIMATION
		var scrollAmount = 100 - Math.round(100 * scrollProgress);
        if (scrollProgress >= 0 && scrollProgress <= 1) {
            $j('.progress-bar').addClass('active');
			$j('.progress-cover').css('width', scrollAmount + "%");
            $j('.progress-container').addClass('active');
        } else if (scrollProgress > 1) {
            $j('.progress-bar').removeClass('active');
			$j('.progress-cover').css('width', "0%");
            $j('.progress-container').removeClass('active');
        } else {
            $j('.progress-bar').removeClass('active');
			$j('.progress-cover').css('width', "100%");
            $j('.progress-container').removeClass('active');
        }
    });
}



var scrollerfn = null;
var _sps = 50;
var scrolledtop = 1;
var prevScrollpos = window.pageYOffset;

$j(window).on('scroll', function() {
    clearTimeout(scrollerfn);
    scrollerfn = setTimeout(scroller, _sps);
});

/*
jQuery(window).bind('scroll', function() {
	if ($(window).scrollTop() > 130) {
		//$('#header').addClass('fixed');
        $('#oc').stop(false,false).animate({'top':"0px"}, {duration:600, easing:'easeOutCirc'});
		$('#oc').removeClass('hidden');
	}	else {
		//$('#header').removeClass('fixed');
        $('#oc').stop(false,false).animate({'top':"-80px"}, {duration:400, easing:'easeOutCirc'});
		$('#oc').addClass('hidden');
	}
});
*/

function fpScroller() {
    if ( $j('body.scrolling').length ) {
        return;
    }
    $j('section.view-region.inview').removeClass('inview');
    var inview = $j('section.view-region:in-viewport:first').find('.anchor>a').attr('id');
    if ( inview == 'overview' ) {
        $j('.fp-header-action ul.cycle-slideshow').cycle('resume');
    } else {
        $j('.fp-header-action ul.cycle-slideshow').cycle('pause');
    }
    $j('section.view-region:in-viewport:first').addClass('inview');
    $j('.sub-nav li.active').removeClass('active');
    $j('ul.nav a[href="#'+inview+'"]').parent().addClass('active');
    $j('.sub-nav .current>span').html($j('ul.nav a[href="#'+inview+'"]').html());
}

function scroller() {
    if ( $j('body.show-top').length ) {
        return;
    }
    var scrolltop = $j(window).scrollTop();
    var currentScrollPos = window.pageYOffset;
    if ( $j('body.single-floorplan').length) {
        fpScroller();
    }
    if (scrolltop > scrolledtop && prevScrollpos < currentScrollPos) {
        if ( ! $j('body.oc-open').length && ! $j('body.oc-akel-open').length ) {
            $j('body').addClass('scrolled');
            $j('#back-to-akel').addClass('scrolled');
        }
    } else {
        $j('body:not(.single-floorplan)').removeClass('scrolled');
        $j('#back-to-akel').removeClass('scrolled');
    }
    if (scrolltop > scrolledtop ) {
        $j('.scrolltop').addClass('show');
        $j('.scrolltop:hidden').stop(true, true).fadeIn();
        $j('body').addClass('started');
    } else {
        $j('.scrolltop').stop(true, true).fadeOut();
        $j('.scrolltop').removeClass('show');
        $j('body').removeClass('started');
        $j('body').removeClass('scrolled');
    }
    if ( $j('div.view-region').length) {
        navScroller();
    }
    prevScrollpos = currentScrollPos;
    if ( !! $j('#solana-bay-map').length ) {
        var heights = window.innerHeight;
        document.getElementById('solana-bay-map').style.height = heights - 82 + "px";
    }

    if ( !! $j('.hero video').length ) {
        if ( $j('.hero .cover:in-viewport').length ) {
            $j('.hero-controls .play:not(.stopped)').click();
            log('play');
        } else if ( $j('.hero .cover').length ) {
            $j('.hero-controls .pause').click();
            log('pause');
        }
    }

    if ( !! $j('.videos-wrap').length ) {
        activeVideo();
    }
}

jQuery.fn.reverse = [].reverse;
$j(window).on('resize resizeEnd', function(event) {
	// reset flying focus
    //$j('#flying-focus').css({'top': 0, 'left': 0, 'right' : 0, 'bottom' : 0});
	var sizer = $j('#msizer').width();
    if ( sizer >= 30) {
        updateNav();
    } else {
        updateAction();
    }
	if ( sizer >= 30 && $j('body.oc-open').length || sizer >= 30 && $j('body.oc-akel-open').length ) {
		// Close mobile menu
        $j('li.menu-item-has-children.open').find('span.fa-minus').addClass('fa-plus').removeClass('fa-minus');
        $j('li.menu-item-has-children.open ul.sub-menu').removeAttr('style');
		$j('li.menu-item-has-children.open').removeClass('open');
		$j('#nav, #akel-nav').removeClass('com-open slide preload');
		$j('#site-overlay').hide();
		$j('#oc-akel-btn').removeClass('is-active');
		$j('body').removeClass('oc-akel-open oc-open');
		$j('.phone-nav, .akel-phone>li.open').find('a>span').text('Call Now');
		$j('.phone-nav, .akel-phone>li.open').removeClass('open');
	}
});

$j.fn.reverse = Array.prototype.reverse;
function updateNav() {
    var available = $j('.header-main .inner').width() - $j('.header-main div.logo').width() - 20;
    var nav = Math.ceil($j('#nav .nav').outerWidth());
    var actions = Math.ceil($j('#nav .actions').outerWidth(true));
    var needed = nav + actions;
    var hidecount = Math.ceil(((available - needed) * -1) / 100);
    if (hidecount > 0) {
        $j('#nav .actions .nav-icon:not(.hide-nav)').reverse().each(function(index) {
            if (index < hidecount) {
                $j(this).addClass('hide-nav');
            }
        });
    } else {
        $j('#nav .actions .nav-icon.hide-nav').slice(0, hidecount * -1).removeClass('hide-nav');
    }
}
function updateAction() {
    var available = $j('.header-main .inner').width() - $j('.header-main div.logo').width();
    var needed = $j('#oc').outerWidth() + $j('.tablet-actions').outerWidth(true);
    var hidecount = Math.ceil(((available - needed) * -1) / 50);
    if (hidecount > 0) {
        $j('.tablet-actions .nav-sizer:not(.hide-nav)').reverse().each(function(index) {
            if (index < hidecount) {
                $j(this).addClass('hide-nav');
            }
        });
    } else {
        $j('.tablet-actions .nav-sizer.hide-nav').slice(0, hidecount * -1).removeClass('hide-nav');
    }
}


function updateNav2() {
    var available_width = $j('.header-main .inner').width() - $j('.header-main div.logo').width();
    var needed_width = Math.ceil($j('#nav .nav').width() + $j('#nav .actions').width() + 10);
    var hidecount = Math.ceil(((available_width - needed_width) * -1) / 100);
    if ( hidecount > 0) {
        $j('#nav .actions p.nav-icon:not(.hide-nav)').reverse().each(function(index){
            if ( index < hidecount) {
                $j(this).addClass('hide-nav');
            }
        });
    } else {
        $j('#nav .actions p.nav-icon.hide-nav').each(function(index){
            if ( index < hidecount * -1) {
                $j(this).removeClass('hide-nav');
            }
        });
    }
}
