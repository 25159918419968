(function ($, root, undefined) {
    $(document).ready(function() {
        $('html').removeClass('no-js');
        updateAction();
        lightboxes();
        mobile();
        nav();
        tabNav();
        locationMap();
        ajaxForm();
        akelWarranty();
        tracking();
        designCenter();
        swipers();
        akelCookies();
        loader();
        floorplanFilters();
        insta();
        countdown();
        booking();
        mcalc();
		singleFloorplan();
		akelLogos();
		faqs();
		blocks();
		animations();
		scrollProgress();
        updateNav();
        updateAction();
        walkIn();
        calendarWidget();
        if ( isSafari() ) {
            //$j('body').addClass('safari');
        }
        if ( $('.hero-controls').length ) {
            $.fn.extend({
                triggerClick: function() {
                    return this.each(function() {
                        $(this).data('triggered', true).click().data('triggered', false);
                    });
                }
            });
            $('.hero-controls.preload').removeClass('preload');
            $('.hero-controls .play').click(function(){
                var el = $(this);
                el.removeClass('active').hide();
                $('.hero-controls .pause').addClass('active').fadeIn(150);
                $('.hero .ss').cycle('resume');
                $('.hero .video video').each(function() {
                    this.play();
                });
            });
            $('.hero-controls .pause').click(function(){
                var el = $(this);
                el.removeClass('active').hide();
                $('.hero-controls .play').addClass('active').fadeIn(150);
                $('.hero .ss').cycle('pause');
                $('.hero .video video').each(function() {
                    this.pause();
                });
            });
            $('.hero .cover, .hero h1, .hero h2').click(function(){
                $('.hero-controls button.active').click();
            });
        }
    });
})(jQuery, this);


