function akelLogos() {
	var akelhomes = bodymovin.loadAnimation({
		container: document.getElementById('akel-homes-logo'),
		path: window.location.origin+'/wp-content/themes/akel/assets/img/a/akel-homes.json',
		renderer: 'svg', loop: false, autoplay: false,
	});
	var sundance = bodymovin.loadAnimation({
		container: document.getElementById('sundance-logo'),
		path: window.location.origin+'/wp-content/themes/akel/assets/img/a/sundance.json',
		renderer: 'svg', loop: false, autoplay: false,
	});
	var sundance2 = bodymovin.loadAnimation({
		container: document.getElementById('sundance-logo2'),
		path: window.location.origin+'/wp-content/themes/akel/assets/img/a/sundance2.json',
		renderer: 'svg', loop: false, autoplay: false,
	});
	var sundancea = bodymovin.loadAnimation({
		container: document.getElementById('sundance-a'),
		path: window.location.origin+'/wp-content/themes/akel/assets/img/a/sundance-a.json',
		renderer: 'svg', loop: false, autoplay: false,
	});
	var sundancea2 = bodymovin.loadAnimation({
		container: document.getElementById('sundance-a2'),
		path: window.location.origin+'/wp-content/themes/akel/assets/img/a/sundance-a2.json',
		renderer: 'svg', loop: false, autoplay: false,
	});
	var akelhomes_top = bodymovin.loadAnimation({
		container: document.getElementById('akel-logo-top'),
		path: window.location.origin+'/wp-content/themes/akel/assets/img/a/akel-homes.json',
		renderer: 'svg', loop: false, autoplay: false,
	});
	var akelhomes_footer = bodymovin.loadAnimation({
		container: document.getElementById('akel-homes-logo-footer'),
		path: window.location.origin+'/wp-content/themes/akel/assets/img/a/akel-homes.json',
		renderer: 'svg', loop: false, autoplay: false,
	});
	var akelhomes_footer_sm = bodymovin.loadAnimation({
		container: document.getElementById('akel-homes-logo-footer-sm'),
		path: window.location.origin+'/wp-content/themes/akel/assets/img/a/akel-homes.json',
		renderer: 'svg', loop: false, autoplay: false,
	});
	var akelhomes_header = bodymovin.loadAnimation({
		container: document.getElementById('akel-homes-logo-header'),
		path: window.location.origin+'/wp-content/themes/akel/assets/img/a/akel-homes.json',
		renderer: 'svg', loop: false, autoplay: false,
	});
	var myakel = bodymovin.loadAnimation({
		container: document.getElementById('myakel-logo'),
		path: window.location.origin+'/wp-content/themes/akel/assets/img/a/myakel.json',
		renderer: 'svg', loop: false, autoplay: false,
	});
	var myakelf = bodymovin.loadAnimation({
		container: document.getElementById('myakel-logo-footer'),
		path: window.location.origin+'/wp-content/themes/akel/assets/img/a/myakel.json',
		renderer: 'svg', loop: false, autoplay: false,
	});
	var akel = bodymovin.loadAnimation({
		container: document.getElementById('akel-logo'),
		path: window.location.origin+'/wp-content/themes/akel/assets/img/a/akel.json',
		renderer: 'svg', loop: false, autoplay: false,
	});
	var akel2 = bodymovin.loadAnimation({
		container: document.getElementById('akel2-logo'),
		path: window.location.origin+'/wp-content/themes/akel/assets/img/a/akel2.json',
		renderer: 'svg', loop: false, autoplay: false,
	});
	var a1 = bodymovin.loadAnimation({
		container: document.getElementById('akel-a'),
		path: window.location.origin+'/wp-content/themes/akel/assets/img/a/akel-a2.json',
		renderer: 'svg', loop: false, autoplay: true,
	});
	var a2 = bodymovin.loadAnimation({
		container: document.getElementById('a2-logo'),
		path: window.location.origin+'/wp-content/themes/akel/assets/img/a/akel-a2.json',
		renderer: 'svg', loop: false, autoplay: false,
	});
	var welcomehome = bodymovin.loadAnimation({
		container: document.getElementById('welcomehome-logo'),
		path: window.location.origin+'/wp-content/themes/akel/assets/img/a/welcome-home.json',
		renderer: 'svg', loop: false, autoplay: true,
	});
	var dbl = bodymovin.loadAnimation({
		container: document.getElementById('dbl-logo'),
		path: window.location.origin+'/wp-content/themes/akel/assets/img/a/dream-build-love.json',
		renderer: 'svg', loop: false, autoplay: false,
	});
	var ds = bodymovin.loadAnimation({
		container: document.getElementById('ds-logo'),
		path: window.location.origin+'/wp-content/themes/akel/assets/img/a/design-studio.json',
		renderer: 'svg', loop: false, autoplay: false,
	});
	var dss = bodymovin.loadAnimation({
		container: document.getElementById('dss-logo'),
		path: window.location.origin+'/wp-content/themes/akel/assets/img/a/design-studio-square.json',
		renderer: 'svg', loop: false, autoplay: false,
	});
	var dss2 = bodymovin.loadAnimation({
		container: document.getElementById('dss-logo2'),
		path: window.location.origin+'/wp-content/themes/akel/assets/img/a/design-studio-square.json',
		renderer: 'svg', loop: false, autoplay: false,
	});
	var solana = bodymovin.loadAnimation({
		container: document.getElementById('solana-logo'),
		path: window.location.origin+'/wp-content/themes/akel/assets/img/a/solana.json',
		renderer: 'svg', loop: false, autoplay: false,
	});
	var solanaf = bodymovin.loadAnimation({
		container: document.getElementById('solana-logo-footer'),
		path: window.location.origin+'/wp-content/themes/akel/assets/img/a/solana.json',
		renderer: 'svg', loop: false, autoplay: false,
	});
	var solanah = bodymovin.loadAnimation({
		container: document.getElementById('solana-logo-header'),
		path: window.location.origin+'/wp-content/themes/akel/assets/img/a/solana.json',
		renderer: 'svg', loop: false, autoplay: false,
	});
	var solana1 = bodymovin.loadAnimation({
		container: document.getElementById('solana1-logo'),
		path: window.location.origin+'/wp-content/themes/akel/assets/img/a/solana-icon-hover.json',
		renderer: 'svg', loop: false, autoplay: false,
	});
	var solana2 = bodymovin.loadAnimation({
		container: document.getElementById('solana2-logo'),
		path: window.location.origin+'/wp-content/themes/akel/assets/img/a/solana-icon-intro.json',
		renderer: 'svg', loop: false, autoplay: false,
	});
	var solana3 = bodymovin.loadAnimation({
		container: document.getElementById('solana3-logo'),
		path: window.location.origin+'/wp-content/themes/akel/assets/img/a/solana-icon-outro.json',
		renderer: 'svg', loop: false, autoplay: false,
	});
	var palms = bodymovin.loadAnimation({
		container: document.getElementById('palms-logo'),
		path: window.location.origin+'/wp-content/themes/akel/assets/img/a/catalina-palms.json',
		renderer: 'svg', loop: false, autoplay: false,
	});
	var palms2 = bodymovin.loadAnimation({
		container: document.getElementById('palms-logo-gold'),
		path: window.location.origin+'/wp-content/themes/akel/assets/img/a/catalina-palms-gold.json',
		renderer: 'svg', loop: false, autoplay: false,
	});
	var mailicon = bodymovin.loadAnimation({
		container: document.getElementById('mail-icon'),
		path: window.location.origin+'/wp-content/themes/akel/assets/img/a/email-hover.json',
		renderer: 'svg', loop: false, autoplay: false,
	});
	var mailicon2 = bodymovin.loadAnimation({
		container: document.getElementById('mail-icon2'),
		path: window.location.origin+'/wp-content/themes/akel/assets/img/a/email-sent.json',
		renderer: 'svg', loop: false, autoplay: false,
	});
    var tagline_intro = bodymovin.loadAnimation({
        container: document.getElementById('tagline-intro'),
        renderer: 'svg',
        loop: false,
        autoplay: false,
        path: window.location.origin+'/wp-content/themes/akel/assets/img/svg/build-a-life-you-love-intro-light.json',
    });
    var tagline_top = bodymovin.loadAnimation({
        container: document.getElementById('tagline-top'),
        renderer: 'svg',
        loop: false,
        autoplay: false,
        path: window.location.origin+'/wp-content/themes/akel/assets/img/a/build/build-a-life-you-love-intro.json',
    });
    var akel_tagline = bodymovin.loadAnimation({
        container: document.getElementById('logo-anim-tagline'),
        renderer: 'svg',
        loop: false,
        autoplay: false,
        path: window.location.origin+'/wp-content/themes/akel/assets/img/svg/build-a-life-you-love-hover.json',
    });

    var cpheader = bodymovin.loadAnimation({
        container: document.getElementById('catalina-palms-header'),
        renderer: 'svg',
        loop: false,
        autoplay: false,
        path: window.location.origin+'/wp-content/themes/akel/assets/img/a/catalina-palms/catalina-palms-logo-animation-hover.json',
    });
    var cpfooter = bodymovin.loadAnimation({
        container: document.getElementById('catalina-palms-footer'),
        renderer: 'svg',
        loop: false,
        autoplay: false,
        path: window.location.origin+'/wp-content/themes/akel/assets/img/a/catalina-palms/catalina-palms-logo-animation-hover.json',
    });
    var cp1 = bodymovin.loadAnimation({
        container: document.getElementById('cp1'),
        renderer: 'svg',
        loop: false,
        autoplay: false,
        path: window.location.origin+'/wp-content/themes/akel/assets/img/a/catalina-palms/catalina-palms-logo-animation-stacked-intro.json',
    });
    var cp2 = bodymovin.loadAnimation({
        container: document.getElementById('cp2'),
        renderer: 'svg',
        loop: false,
        autoplay: false,
        path: window.location.origin+'/wp-content/themes/akel/assets/img/a/catalina-palms/catalina-palms-logo-animation-stacked-hover.json',
    });
    var cp3 = bodymovin.loadAnimation({
        container: document.getElementById('cp3'),
        renderer: 'svg',
        loop: false,
        autoplay: false,
        path: window.location.origin+'/wp-content/themes/akel/assets/img/a/catalina-palms/catalina-palms-logo-animation-intro-web.json',
    });
    var cp4 = bodymovin.loadAnimation({
        container: document.getElementById('cp4'),
        renderer: 'svg',
        loop: false,
        autoplay: false,
        path: window.location.origin+'/wp-content/themes/akel/assets/img/a/catalina-palms/catalina-palms-logo-animation-intro-web.json',
    });
    var cp5 = bodymovin.loadAnimation({
        container: document.getElementById('cp5'),
        renderer: 'svg',
        loop: false,
        autoplay: false,
        path: window.location.origin+'/wp-content/themes/akel/assets/img/a/catalina-palms/catalina-palms-logo-animation-logo-hover-web.json',
    });
    var cp6 = bodymovin.loadAnimation({
        container: document.getElementById('cp6'),
        renderer: 'svg',
        loop: false,
        autoplay: false,
        path: window.location.origin+'/wp-content/themes/akel/assets/img/a/catalina-palms/catalina-palms-logo-animation-logo-intro-web.json',
    });
    var sd1 = bodymovin.loadAnimation({
        container: document.getElementById('sd1'),
        renderer: 'svg',
        loop: false,
        autoplay: false,
        path: window.location.origin+'/wp-content/themes/akel/assets/img/a/sundance/sundance-stacked-flying-loop.json',
    });
    var sd4 = bodymovin.loadAnimation({
        container: document.getElementById('sd4'),
        renderer: 'svg',
        loop: false,
        autoplay: false,
        path: window.location.origin+'/wp-content/themes/akel/assets/img/a/sundance/sundance-logo-animation-stacked-bird-hover.json',
    });
    var sd5 = bodymovin.loadAnimation({
        container: document.getElementById('sd5'),
        renderer: 'svg',
        loop: true,
        autoplay: true,
        path: window.location.origin+'/wp-content/themes/akel/assets/img/a/sundance/sundance-stacked-flying-hover-980.json',
    });
    var sd6 = bodymovin.loadAnimation({
        container: document.getElementById('sd6'),
        renderer: 'svg',
        loop: false,
        autoplay: false,
        path: window.location.origin+'/wp-content/themes/akel/assets/img/a/sundance/sundance-horizontal-flying-hover-980.json',
    });
    var sd7 = bodymovin.loadAnimation({
        container: document.getElementById('sd7'),
        renderer: 'svg',
        loop: false,
        autoplay: false,
        path: window.location.origin+'/wp-content/themes/akel/assets/img/a/sundance/sundance-logo-animation-stacked-hover.json',
    });
    var sundanceintro = bodymovin.loadAnimation({
        container: document.getElementById('sundance-intro'),
        renderer: 'svg',
        loop: false,
        autoplay: false,
        //path: window.location.origin+'/wp-content/themes/akel/assets/img/a/sundance/sundance-stacked-flying-hover-980.json',
        path: window.location.origin+'/wp-content/themes/akel/assets/img/a/sundance/sundance-logo-animation-stacked-bird-hover.json',
    });
    var build = bodymovin.loadAnimation({
        container: document.getElementById('build'),
        renderer: 'svg',
        loop: false,
        autoplay: false,
        path: window.location.origin+'/wp-content/themes/akel/assets/img/a/build/build-a-life-you-love-intro.json',
    });
    var buildfooter = bodymovin.loadAnimation({
        container: document.getElementById('build-footer'),
        renderer: 'svg',
        loop: false,
        autoplay: false,
        path: window.location.origin+'/wp-content/themes/akel/assets/img/a/build/build-a-life-you-love-intro.json',
    });
    var buildig = bodymovin.loadAnimation({
        container: document.getElementById('buildig'),
        renderer: 'svg',
        loop: false,
        autoplay: false,
        path: window.location.origin+'/wp-content/themes/akel/assets/img/a/build/build-a-life-you-love-intro.json',
    });
    $j('.animate').mouseenter(function(){
        var el = $j(this);
        var source = el.data('json');
        if ( $j(window).width() < 1024 && source != 'tagline') {
            //return;
        }
        el.removeClass('preload');
		if ( source == 'sundance-a' ) { var logo = sundancea; }
		if ( source == 'sundance-a2' ) { var logo = sundancea2; }
		if ( source == 'sundance' ) { var logo = sundance; }
		if ( source == 'sundance2' ) { var logo = sundance2; }
		if ( source == 'akelhomes' ) { var logo = akelhomes; }
		if ( source == 'akelhomes-top' ) { var logo = akelhomes_top; }
		if ( source == 'akelhomesf' ) { var logo = akelhomes_footer; }
		if ( source == 'akelhomesfsm' ) { var logo = akelhomes_footer_sm; }
		if ( source == 'akelhomesh' ) { var logo = akelhomes_header; }
		if ( source == 'myakel' ) { var logo = myakel; }
		if ( source == 'myakelf' ) { var logo = myakelf; }
		if ( source == 'solana' ) { var logo = solana; }
		if ( source == 'solanaf' ) { var logo = solanaf; }
		if ( source == 'solanah' ) { var logo = solanah; }
		if ( source == 'akel') { var logo = akel; }
		if ( source == 'akel2') { var logo = akel2; }
		if ( source == 'a1') { var logo = a1; }
		if ( source == 'a2') { var logo = a2; }
		if ( source == 'welcomehome') { var logo = welcomehome; }
		if ( source == 'dbl') { var logo = dbl; }
		if ( source == 'build') { var logo = build; }
		if ( source == 'buildig') { var logo = buildig; }
		if ( source == 'buildfooter') { var logo = buildfooter; }
		if ( source == 'ds') { var logo = ds; }
		if ( source == 'dss') { var logo = dss; }
		if ( source == 'dss2') { var logo = dss2; }
		if ( source == 'solana1') { var logo = solana1; }
		if ( source == 'solana2') { var logo = solana2; }
		if ( source == 'solana3') { var logo = solana3; }
		if ( source == 'palms') { var logo = palms; }
		if ( source == 'palms2') { var logo = palms2; }
		if ( source == 'mailicon') { var logo = mailicon; }
		if ( source == 'mailicon2') { var logo = mailicon2; }
		if ( source == 'tagline-intro') { var logo = tagline_intro; }
		if ( source == 'tagline-top') { var logo = tagline_top; }
		if ( source == 'cpheader') { var logo = cpheader; }
		if ( source == 'cpfooter') { var logo = cpfooter; }
		if ( source == 'cp3') { var logo = cp3; }
		if ( source == 'cp1') { var logo = cp1; }
		if ( source == 'cp2') { var logo = cp2; }
		if ( source == 'cp3') { var logo = cp3; }
		if ( source == 'cp4') { var logo = cp4; }
		if ( source == 'cp5') { var logo = cp5; }
		if ( source == 'cp6') { var logo = cp6; }
		if ( source == 'sd1') { var logo = sd1; }
		if ( source == 'sd4') { var logo = sd4; }
		if ( source == 'sd5') { var logo = sd5; }
		if ( source == 'sd6') { var logo = sd6; }
		if ( source == 'sd7') { var logo = sd7; }
		if ( source == 'sundanceintro') { var logo = sundanceintro; }
		if ( ! logo ) {
			return;
		}
        logo.stop();
        logo.play();
	});
	$j('.email-icon .animate.preload').removeClass('preload');
	var mailanimate = '';
    $j('.fs-main').mouseenter(function(){
		clearTimeout(mailanimate);
		mailanimate = setTimeout(function(){
			$j('.email-icon').removeClass('success');
			mailicon.stop();
			mailicon.play();
		},100);
	});
    $j('form.subscribe button').click(function(){
		clearTimeout(mailanimate);
		$j('.email-icon').addClass('success');
		mailicon2.stop();
		mailicon2.play();
	});
    setTimeout(function(){
        tagline_intro.play();
        build.play();
        buildig.play();
        sundanceintro.play();
        dbl.play();
        welcomehome.play();
    }, 500);
    function animateInstaTitle() {
		buildig.stop();
        buildig.play();
    }
    function animateWelcomeTitle() {
		dbl.stop();
        dbl.play();
		cp3.stop();
        cp3.play();
		welcomehome.stop();
        welcomehome.play();
    }
    function animateSundance() {
		sundanceintro.stop();
        sundanceintro.play();
    }
	inView('.dbl.akel-feature').on('enter', animateWelcomeTitle);
	inView('.igfeed').on('enter', animateInstaTitle);
	inView('.animate.sd').on('enter', animateSundance);
}
